export async function readFile(file) {
    const response = await fetch(file)
    return await response.text()
}

export function renderData(data) {

    return {
        name: data.name,
        content: {
            video: data.video,
            images: data.images,
            src: data.src,
            text: data.ro ? readFile(data.file_ro) : readFile(data.file_en)
        }
    }

}