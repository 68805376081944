import React, {useEffect, useState} from 'react'
import Hero from "./components/hero";
import History from "./components/history";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../services/query";
import {homeText} from "../../locale";

export const HomePath = '/'

const HomePage = () => {
    const query = useQuery()
    const history = useHistory()
    const lang = query.get('lang')
    const text = homeText(lang)

    useEffect(() => {
        let search = ''


        switch (lang) {
            case 'en':
                search = 'lang=en'
                break
            case 'ro':
                search = 'lang=ro'
                break
            default:
                search = 'lang=en'

        }
        if (lang !== 'en' || lang !== 'ro') {
            history.push({
                pathname: '/',
                search: search
            })
        }
    }, [lang])

    console.log('TEXT: ', text)


    return <div>
        <section>
            <Hero text={text} lang={lang}/>
        </section>
        <section>
            <History text={text} lang={lang}/>
        </section>
    </div>
}

export default HomePage