import Pages from "./pages"


const App = () => {
    return (
        <div>
            <Pages/>
        </div>
    )
}
export default App;
