import style from './index.module.sass'
import Logo from '../../assets/images/logo.png'
import {useHistory} from "react-router-dom";
import {useQuery} from "../../services/query";

const Header = () => {
    const history = useHistory()
    const query = useQuery()
    const lang = query.get('lang')

    function changeLanguage(language) {
        history.push({
            pathname: '/',
            search: `?lang=${language}`
        })

        window.location.reload(false)
    }

    return (
        <div className={style.box}>
            <div>
                <div className={style.boxLogo}>
                    <img src={Logo} alt=""/>
                </div>
            </div>
            <div className={style.boxLang}>

                <span className={`${style.boxLangText} ${lang === 'en' && style.boxLangTextActive}`}
                      onClick={() => changeLanguage('en')}>EN</span>
                <span className={style.boxBreakText}>/</span>
                <span className={`${style.boxLangText} ${lang === 'ro' && style.boxLangTextActive}`}
                      onClick={() => changeLanguage('ro')}>RO</span>

            </div>
        </div>
    )
}

export default Header