import Image1 from "./images/1.png";
import Image2 from "./images/2.png";
import TEXT_EN from "./text_en.txt";
import TEXT_RO from "./text_ro.txt";
import {renderData} from "../../../services/file";


export const project_5 = (ro=true) => {
    const data = {
        name: 'Online Shop',
        video: '693550491',
        images: [
            Image1, Image2
        ],
        src: "",
        file_en: TEXT_EN,
        file_ro: TEXT_RO,
        ro: ro
    }
    return renderData(data)
}