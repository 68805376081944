import React from 'react'
import {Route, BrowserRouter, Switch, Redirect} from "react-router-dom";
import HomePage, {HomePath} from "./home";
import Header from "../components/header";
import style from './layout.module.sass'
import Footer from "../components/footer";


const Pages = () => {
    return (
        <div className={style.box}>
            <div className={style.boxContent}>
                <BrowserRouter>
                    <header>
                        <Header/>
                    </header>
                    <Switch>
                        <Route path={HomePath} exact><HomePage/></Route>
                        <Route><Redirect to={HomePath}/></Route>
                    </Switch>
                    <footer>
                        <Footer/>
                    </footer>
                </BrowserRouter>
            </div>
        </div>
    )
}

export default Pages