import Image1 from './images/1.png'
import Image2 from './images/2.png'
import Image3 from './images/3.png'
import Image4 from './images/4.png'
import Image5 from './images/5.png'
import Image6 from './images/6.png'
import Image7 from './images/7.png'
import Image8 from './images/8.png'
import TEXT_EN from './text_en.txt'
import TEXT_RO from './text_ro.txt'
import {renderData} from "../../../services/file";


export const project_1 = (ro = true) => {
    const data = {
        name: 'Immersive',
        video: '693549576',
        images: [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8],
        src: "",
        file_en: TEXT_EN,
        file_ro: TEXT_RO,
        ro: ro
    }

    return renderData(data)
}
