import React, {useEffect, useState} from 'react'
import style from "./index.module.sass";
import "video-react/dist/video-react.css";
import {setBackgroundImage} from "../../../../../services/image";
import Vimeo from "react-vimeo";


const ProjectContent = ({data}) => {
    const activeInitialState = {
        image: false,
        src: data.video
    }
    const [active, setActive] = useState(activeInitialState)
    const [text, setText] = useState('')

    useEffect(() => {
        (async function fetchData() {
            const promise = await data.text
            setText(promise)
        })()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={style.box}>
            <div className={style.boxFull}>

                {
                    active.image
                        ?
                        <div style={setBackgroundImage(active.src, 'top')}>&nbsp;</div>
                        :
                        <div className={style.boxVideo}>
                            <Vimeo videoId={active.src} autoplay={true}/>
                        </div>
                }


            </div>
            <div className={style.boxGallery}>
                {
                    data.images.map((o, index) => <div key={o}>
                        <div
                            style={setBackgroundImage(o, 'top')}
                            onClick={() => {
                                if (index === 0) {
                                    setActive({
                                        image: false,
                                        src: data.video
                                    })
                                } else {
                                    setActive({
                                        image: true,
                                        src: o
                                    })
                                }
                            }}
                        >&nbsp;</div>
                    </div>)
                }
            </div>
            <div className={style.boxText}>
                <p>{text}</p>
            </div>
            {
                data.src !== ""
                &&
                <div className={style.boxAction}>
                    <a href={data.src} target="_blank" rel="noreferrer">
                        <button>Vezi proiect</button>
                    </a>
                </div>
            }
        </div>
    )
}

export default ProjectContent