import TEXT_EN from "./text_en.txt";
import TEXT_RO from "./text_ro.txt";
import {renderData} from "../../../services/file";
import Image1 from "./images/1.webp";
import Image2 from "./images/2.webp";
import Image3 from "./images/3.webp";
import Image4 from "./images/4.webp";
import Image5 from "./images/5.webp";

export const project_19 = (ro = true) => {
    const data = {
        name: 'Refurbishing injectors presentation website',
        video: '855628405',
        images: [Image1, Image2, Image3, Image4, Image5],
        src: 'https://injectoare-sibiu.ro/',
        file_en: TEXT_EN,
        file_ro: TEXT_RO,
        ro: ro
    }
    return renderData(data)
}