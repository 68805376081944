import Image1 from "./images/1.png";
import Image2 from "./images/2.png";
import Image3 from "./images/3.png";
import Image4 from "./images/4.png";
import Image5 from "./images/5.png";
import Image6 from "./images/6.png";
import Image7 from "./images/7.png";
import Image8 from "./images/8.png";
import Image9 from "./images/9.png";
import Image10 from "./images/10.png";
import Image11 from "./images/11.png";
import Image12 from "./images/12.png";
import Image13 from "./images/13.png";
import Image14 from "./images/14.png";
import Image15 from "./images/15.png";
import Image16 from "./images/16.png";
import Image17 from "./images/17.png";
import TEXT_EN from "./text_en.txt";
import TEXT_RO from "./text_ro.txt";
import {renderData} from "../../../services/file";

export const project_12 = (ro=true) => {
    const data = {
        name: 'Shop Admin',
        video: '693552387',
        images: [
            Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10,
            Image11, Image12, Image13, Image14, Image15, Image16, Image17
        ],
        src: "https://admin.shops.calinvladth.com",
        file_en: TEXT_EN,
        file_ro: TEXT_RO,
        ro: ro
    }
    return renderData(data)
}