import React, {useEffect, useState} from 'react'
import style from './index.module.sass'
import ProjectContent from "./project_content";
import AllProjects from "../../../../data";
import {useQuery} from "../../../../services/query";

const History = ({text}) => {
    const [data, setData] = useState([])
    const [activeYear, setActiveYear] = useState({})
    const [activeProject, setActiveProject] = useState({})
    const query = useQuery()
    const lang = query.get('lang')

    useEffect(() => {

        setData(AllProjects(lang))
        if (data.length > 0 && data) {
            setActiveYear(data[0])
            setActiveProject({})
        }
        // eslint-disable-next-line
    }, [data.length, lang])


    if (data && data.length > 0) {

        return (
            <div className={style.box}>

                <div>
                    <h1>{text.history.title}</h1>
                </div>
                <div>
                    <Years
                        data={data}
                        active={activeYear}
                        setActive={setActiveYear}
                        setActiveProject={setActiveProject}
                    />
                </div>


                {
                    Object.entries(activeYear).length > 0 && <div>
                        <Projects data={activeYear} active={activeProject} setActive={setActiveProject}/>
                    </div>
                }

            </div>
        )
    } else {
        return <div className={style.box}>
            <p>{text.loading}</p>
        </div>
    }


}

const Years = ({data, active, setActive, setActiveProject}) => (
    <div className={style.years}>
        {
            data.map(o => <div key={o.year} onClick={() => {
                if (active.year === o.year) {
                    setActive({})
                    setActiveProject({})
                } else setActive(o)

            }}>
                <p>{o.year} <span
                    className={`${style.arrow} ${active.year === o.year ? style.arrowActive : null}`}>&#x25BA;</span>
                </p>
            </div>)
        }
    </div>
)

const Projects = ({data, active, setActive}) => (
    <div className={style.projects}>
        {
            data.projects.map(o => <div key={o.name}>
                <div className={style.projectsTitle} onClick={() => {
                    if (!o.in_progress) {
                        if (active.name === o.name) setActive({})
                        else {
                            setActive(o)
                        }
                    }

                }}>
                    <h1>{o.name} {
                        !o.in_progress &&
                        <span
                            className={`${style.arrow} ${active.name === o.name ? style.arrowActive : null}`}>&#x25BA;</span>
                    }
                    </h1>
                </div>
                {
                    active.name === o.name && <ProjectContent data={o.content}/>
                }
            </div>)
        }
    </div>
)

export default History